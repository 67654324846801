'use client';

import * as React from 'react';

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import HomeRepairServiceRoundedIcon from '@mui/icons-material/HomeRepairServiceRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ShoppingCartCheckoutRoundedIcon from '@mui/icons-material/ShoppingCartCheckoutRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import type { Session } from 'next-auth';
import { signOut } from 'next-auth/react';
import { Link } from 'next-view-transitions';
import Image from 'next/image';

import { useI18n } from '@pegase/i18n/client';

import { defaultAvatar } from '~/utils/constants';
import { getImageUrl } from '~/utils/image';

interface Props {
  session: Session;
}

const HeaderProfile = (props: Props) => {
  const t = useI18n();

  const [menuProfileOpen, setMenuProfileOpen] = React.useState(false);
  const [menuProfileAnchorEl, setMnuProfileAnchorEl] = React.useState<null | HTMLElement>(null);

  const _openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMnuProfileAnchorEl(event.currentTarget);
    setMenuProfileOpen(true);
  };

  const _closeMenu = () => setMenuProfileOpen(false);

  const _logout = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    _closeMenu();
    await signOut({ callbackUrl: '/' });
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label={t('common.profile')}
        aria-owns={menuProfileOpen ? 'profile-menu' : undefined}
        aria-haspopup="menu"
        onClick={_openMenu}>
        <Avatar>
          <Image
            src={getImageUrl(defaultAvatar, props.session.user.image)}
            alt={props.session.user.name ?? ''}
            width={40}
            height={40}
            priority
            style={{ objectFit: 'cover' }}
          />
        </Avatar>
      </IconButton>
      <Menu
        id="profile-menu"
        role="menu"
        anchorEl={menuProfileAnchorEl}
        open={menuProfileOpen}
        onClose={_closeMenu}
        transitionDuration={200}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              width: 220,
              maxWidth: '100%',
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: { xs: 8, lg: 22 },
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}>
        <MenuItem component={Link} href="/account" onClick={_closeMenu}>
          <ListItemIcon>
            <AccountCircleRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('common.profile')} />
        </MenuItem>
        <MenuItem component={Link} href="/account/dashboard" onClick={_closeMenu}>
          <ListItemIcon>
            <DashboardRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('common.dashboard')} />
        </MenuItem>
        {props.session.user.hasProfession && (
          <MenuItem component={Link} href="/account/profession" onClick={_closeMenu}>
            <ListItemIcon>
              <HomeRepairServiceRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('common.my_professions')} />
          </MenuItem>
        )}
        {props.session.user.hasProfession && (
          <MenuItem component={Link} href="/account/availability" onClick={_closeMenu}>
            <ListItemIcon>
              <EventRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('common.my_availabilities')} />
          </MenuItem>
        )}
        <MenuItem component={Link} href="/account/booking" onClick={_closeMenu}>
          <ListItemIcon>
            <ShoppingCartCheckoutRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('common.my_bookings')} />
        </MenuItem>
        <MenuItem component={Link} href="/contact" onClick={_closeMenu}>
          <ListItemIcon>
            <SupportRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('common.help')} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={_logout}>
          <ListItemIcon>
            <LogoutRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('common.logout')} />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default HeaderProfile;
