'use client';

import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import DesktopWindowsRoundedIcon from '@mui/icons-material/DesktopWindowsRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import MuiLink from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useColorScheme } from '@mui/material/styles';
import { Link } from 'next-view-transitions';

import { useI18n } from '@pegase/i18n/client';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import dayjs from 'dayjs';
import { grayDark } from '~/utils/colors';
import data from '../../../package.json';

const Footer = () => {
  const t = useI18n();

  return (
    <Box
      component="footer"
      sx={{
        mt: 'auto',
      }}>
      <Stack spacing={0}>
        <Box
          sx={{
            bgcolor: grayDark[30],
            color: 'rgb(189, 193, 198)',
            pt: 2,
            pb: 2,
            flexShrink: 0,
          }}>
          <Container>
            <Grid container>
              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                }}
              />
              <Grid
                size={{
                  xs: 6,
                  sm: 3,
                }}>
                <Stack spacing={1}>
                  <Typography component="h3" color="grey.100" pb={2} variant="subtitle1" fontWeight="bold">
                    {t('common.get_involved')}
                  </Typography>
                  <MuiLink component={Link} href="/profession/create" color="secondary" underline="hover" sx={{ fontWeight: 'bold' }}>
                    {t('common.add_profession')}
                  </MuiLink>
                  <MuiLink component={Link} href="/contact" color="grey.500" underline="hover">
                    {t('common.contact')}
                  </MuiLink>
                  <MuiLink component={Link} href="/faq" color="grey.500" underline="hover">
                    {t('common.faq')}
                  </MuiLink>
                </Stack>
              </Grid>
              <Grid
                size={{
                  xs: 6,
                  sm: 3,
                }}>
                <Stack spacing={1}>
                  <Typography component="h3" color="grey.100" pb={2} variant="subtitle1" fontWeight="bold">
                    {t('common.company')}
                  </Typography>
                  <MuiLink component={Link} href="/privacy" color="grey.500" underline="hover">
                    {t('common.privacy_policy')}
                  </MuiLink>
                  <MuiLink component={Link} href="/terms" color="grey.500" underline="hover">
                    {t('common.terms_of_services')}
                  </MuiLink>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Divider color="grey" />
        <SubFooter />
      </Stack>
    </Box>
  );
};

const ModeSwitcher = () => {
  const { mode, setMode } = useColorScheme();

  if (!mode) {
    return null;
  }

  const _mode = (_event: React.MouseEvent<HTMLElement>, newAlignment: 'light' | 'dark' | 'system') => {
    setMode(newAlignment);
  };

  return (
    <ToggleButtonGroup value={mode} exclusive onChange={_mode} aria-label="theme mode" size="small">
      <ToggleButton
        value="light"
        aria-label="light mode"
        sx={{
          '&.Mui-selected': { backgroundColor: 'rgba(255 255 255 / 0.16)' },
          '&:hover': { backgroundColor: 'rgba(255 255 255 / 0.24) !important' },
        }}>
        <LightModeRoundedIcon sx={{ color: 'white' }} />
      </ToggleButton>
      <ToggleButton
        value="system"
        aria-label="system mode"
        sx={{
          '&.Mui-selected': { backgroundColor: 'rgba(255 255 255 / 0.16)' },
          '&:hover': { backgroundColor: 'rgba(255 255 255 / 0.24) !important' },
        }}>
        <DesktopWindowsRoundedIcon sx={{ color: 'white' }} />
      </ToggleButton>
      <ToggleButton
        value="dark"
        aria-label="dark mode"
        sx={{
          '&.Mui-selected': { backgroundColor: 'rgba(255 255 255 / 0.16)' },
          '&:hover': { backgroundColor: 'rgba(255 255 255 / 0.24) !important' },
        }}>
        <DarkModeRoundedIcon sx={{ color: 'white' }} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const SubFooter = () => {
  const t = useI18n();

  return (
    <Paper
      square
      elevation={0}
      sx={{
        bgcolor: grayDark[30],
        color: 'rgb(189, 193, 198)',
        pt: 1,
        pb: 1,
      }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          px: { xs: 1, sm: 2 },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography>{`\u00A9 ${t('common.site_name')} ${dayjs().format('YYYY')} - ${t('common.all_right_reserved')} v${
          data.version
        }`}</Typography>
        <ModeSwitcher />
      </Stack>
    </Paper>
  );
};

export default Footer;
